<template>
  <div class="card py-3 mb-10">
    <form class="card-body">
      <div class="row gx-10 gy-10">
        <div class="col-xl-3">
          <label class="fs-3 fw-bold pb-2 d-block" for="province"
            >Province</label
          >
          <div class="d-flex position-relative">
            <select
              v-model="provinceModel"
              class="form-select ps-0 pe-4"
              name="province"
              id="province"
            >
              <option
                :key="i"
                v-for="(province, i) in provinces"
                :value="province.id"
              >
                {{ province.name }}
              </option>
            </select>
            <span class="material-icons-outlined position-absolute">
              arrow_drop_down
            </span>
          </div>
        </div>
      </div>
      <div class="row gx-10 gy-10 pt-8">
        <div class="col-xl-3">
          <label class="fs-3 fw-bold pb-2 d-block" for="name"
            >Location Name</label
          >
          <div class="d-flex position-relative">
            <input
              id="name"
              class="form-select ps-0 pe-4"
              type="text"
              v-model="node.name"
              placeholder="-"
            />
            <span class="material-icons-outlined position-absolute fs-3">
              edit
            </span>
          </div>
        </div>
        <div class="col-xl-3">
          <label class="fs-3 fw-bold pb-2" for="latitude"> Latitude </label>
          <div class="d-flex position-relative">
            <input
              class="form-select ps-0 pe-4"
              name="Latitude"
              id="latitude"
              v-model="node.latitude"
              type="number"
            />
            <span class="material-icons-outlined position-absolute fs-2">
              gps_fixed
            </span>
          </div>
        </div>
        <div class="col-xl-3">
          <label class="fs-3 fw-bold pb-2" for="longitude"> Longitude </label>
          <div class="d-flex position-relative">
            <input
              class="form-select ps-0 pe-4"
              name="Longitude"
              id="longitude"
              v-model="node.longitude"
              type="number"
            />
            <span class="material-icons-outlined position-absolute fs-2">
              gps_fixed
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="card py-3">
    <div class="card-body">
      <h3>Pin Location</h3>
      <GMapMap
        class="h-300px pt-5 rounded"
        :center="{ lat: node.latitude, lng: node.longitude }"
        :zoom="15"
        map-type-id="terrain"
        ref="mapRef"
      >
        <GMapMarker
          :position="{ lat: node.latitude, lng: node.longitude }"
          :clickable="true"
          :draggable="false"
        />
      </GMapMap>
    </div>
  </div>

  <div class="d-flex justify-content-end pt-7">
    <button
      class="btn btn-primary fs-3 fw-bolder px-10"
      data-bs-toggle="modal"
      data-bs-target="#modal"
    >
      Submit Changes
    </button>
  </div>

  <div
    class="modal fade"
    id="modal"
    tabindex="-1"
    aria-labelledby="confirm"
    aria-hidden="true"
    data-bs-backdrop="static"
    ref="modalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body px-12">
          <h1 class="text-center">Confirmation</h1>
          <p class="fs-2 pt-2 text-center">
            Would you like to confirm your action?
          </p>
          <div class="d-flex justify-content-between pt-4">
            <button
              type="button"
              name="cancel"
              class="btn btn-danger px-10"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              @click="submit()"
              type="button"
              name="confirm"
              class="btn btn-primary px-10"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import ApiService from '@/core/services/ApiService';
import { Actions } from '@/store/enums/StoreEnums';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { hideModal } from '@/core/helpers/dom';

export default {
  name: 'NodeListsWildcard',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { id } = route.params;
    const provinces = ref([]);
    const node = ref({});
    const mapRef = ref(null);
    const modalRef = ref(null);
    const provinceModel = ref('');

    onMounted(async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');
      const provinceData = await ApiService.get(
        'location/province?pageSize=100'
      );
      const nodeData = await ApiService.get(`location/service-point/${id}`);

      provinces.value = orderBy(provinceData.data.datas, ['name'], ['asc']);

      node.value = nodeData.data.data;
      provinceModel.value = provinces.value.find(
        (province) => province.name === node.value.province.name
      ).id;

      setCurrentPageBreadcrumbs(
        'Location',
        [{ name: 'Node List', path: `/location/node-list` }, 'Edit Node'],
        {}
      );
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');

      const map = await mapRef.value.$mapPromise;
      map.addListener('click', (mapsMouseEvent) => {
        node.value.latitude = mapsMouseEvent.latLng.lat();
        node.value.longitude = mapsMouseEvent.latLng.lng();
      });
    });

    const submit = async () => {
      const data = {
        name: node.value.name,
        latitude: node.value.latitude,
        longitude: node.value.longitude,
        province: provinceModel.value,
      };

      await ApiService.patch(`location/service-point/${id}`, data);

      hideModal(modalRef.value);

      await Swal.fire({
        text: 'Node has been successfully updated.',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Go to node lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      router.push({ name: 'Node Lists' });
    };

    return {
      provinces,
      node,
      mapRef,
      submit,
      modalRef,
      provinceModel,
    };
  },
};
</script>

<style scoped lang="sass">
input, select, select:disabled
  font-size: 1.5rem
  font-weight: 600
  background: transparent
  border: none
  border-bottom: 2px solid #67BEAC
  border-radius: 0

.material-icons-outlined.position-absolute
  right: 0
  margin-top: 12px

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield

input:focus
  outline: none
</style>
